/**
 * Form elements
 * Checkboxes & Radios
 */

[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  margin-top: -0.125em;
  margin-right: 0.375em;
  margin-left: 0;
  margin-inline-end: 0.375em;
  margin-inline-start: 0;
  border-width: var(--border-width);
  vertical-align: middle;
  cursor: pointer;

  &::-ms-check {
    display: none; // unstyle IE checkboxes
  }

  &:checked,
  &:checked:active,
  &:checked:focus {
    --background-color: var(--primary);
    --border-color: var(--primary);
    background-image: var(--icon-checkbox);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.75em auto;
  }

  & ~ label {
    display: inline-block;
    margin-right: 0.375em;
    margin-bottom: 0;
    cursor: pointer;
  }
}

// Checkboxes
[type="checkbox"] {
  &:indeterminate {
    --background-color: var(--primary);
    --border-color: var(--primary);
    background-image: var(--icon-minus);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.75em auto;
  }
}

// Radios
[type="radio"] {
  border-radius: 50%;

  &:checked,
  &:checked:active,
  &:checked:focus {
    --background-color: var(--primary-inverse);
    border-width: 0.35em;
    background-image: none;
  }
}

// Switchs
[type="checkbox"][role="switch"] {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
  --color: var(--switch-color);

  // Config
  $switch-height: 1.25em;
  $switch-width: 2.25em;
  $switch-transition: 0.1s ease-in-out;

  // Styles
  width: $switch-width;
  height: $switch-height;
  border: var(--border-width) solid var(--border-color);
  border-radius: $switch-height;
  background-color: var(--background-color);
  line-height: $switch-height;

  &:focus {
    --background-color: var(--switch-background-color);
    --border-color: var(--switch-background-color);
  }

  &:checked {
    --background-color: var(--switch-checked-background-color);
    --border-color: var(--switch-checked-background-color);
  }

  &:before {
    display: block;
    width: calc(#{$switch-height} - (var(--border-width) * 2));
    height: 100%;
    border-radius: 50%;
    background-color: var(--color);
    content: "";

    @if $enable-transitions {
      transition: margin $switch-transition;
    }
  }

  &:checked {
    background-image: none;

    &::before {
      margin-right: 0;
      margin-left: calc(#{$switch-width * 0.5} - var(--border-width));
      margin-inline-end: 0;
      margin-inline-start: calc(#{$switch-width * 0.5} - var(--border-width));
    }
  }

  // Aria-invalid
  &[aria-invalid="false"] {
    --border-color: var(--form-element-valid-border-color);

    &:active,
    &:focus {
      @if $enable-important {
        --border-color: var(
          --form-element-valid-active-border-color
        ) !important;
      } @else {
        --border-color: var(--form-element-valid-active-border-color);
      }
    }
  }

  &[aria-invalid="true"] {
    --border-color: var(--form-element-invalid-border-color);

    &:active,
    &:focus {
      @if $enable-important {
        --border-color: var(
          --form-element-invalid-active-border-color
        ) !important;
      } @else {
        --border-color: var(--form-element-invalid-active-border-color);
      }
    }
  }
}
