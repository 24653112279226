/**
 * Tooltip ([data-tooltip])
 */

[data-tooltip] {
  position: relative;

  &:not(a):not(button):not(input) {
    border-bottom: 1px dotted;
    text-decoration: none;
    cursor: help;
  }

  &::before,
  &::after {
    display: block;
    z-index: 99;
    position: absolute;
    bottom: 100%;
    left: 50%;
    padding: .25rem .5rem;
    overflow: hidden;
    transform: translate(-50%, -.25rem);
    border-radius: var(--border-radius);
    background: var(--tooltip-background-color);
    color: var(--tooltip-color);
    font-size: .875rem;
    font-style: normal;
    font-weight: var(--font-weight);
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    content: attr(data-tooltip);
    opacity: 0;
    pointer-events: none;
  }

  // Caret
  &::after {
    padding: 0;
    transform: translate(-50%, 0rem);
    border-top: .3rem solid;
    border-right: .3rem solid transparent;
    border-left: .3rem solid transparent;
    border-radius: 0;
    background-color: transparent;
    color: var(--tooltip-background-color);
    content: '';
  }

  &:focus,
  &:hover {
    &::before,
    &::after {
      opacity: 1;

      @if $enable-transitions {
        animation-name: slide;
        animation-duration: .2s;
      }
    }

    &::after {
      @if $enable-transitions {
        animation-name: slideCaret;
      }
    }
  }
}

// Animations
@if $enable-transitions {
  @keyframes slide {
    from {
      transform: translate(-50%, .75rem);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -.25rem);
      opacity: 1;
    }
  }

  @keyframes slideCaret {
    from {
      opacity: 0;
    }
    50% {
      transform: translate(-50%, -.25rem);
      opacity: 0;
    }
    to {
      transform: translate(-50%, 0rem);
      opacity: 1;
    }
  }
}
