@import "npm:@picocss/pico/scss/pico";

$black: #1A181B;

$light: #FBAF00;
$dark : #005f5f;
$teal : #099;
$text : #DBF9F4;

$color-1: #58B09C;
$color-2: #CAF7E2;
$color-3: #C44900;
$color-4: #432534;

body {
    // background-color   : $dark;
    min-height         : 100vh;
    // color           : $text !important;
    overflow-x         : hidden;
}

header {
    margin-bottom: 0px;
}

blockquote {
    border    : none;
    padding   : 0px;
    margin    : 0px;
    margin-top: 1em;
}

.lottie-plant {
    max-width: 60%;
    display  : flex;
    float    : right;
}



img.feli {
    align-self      : flex-start;
    width           : 7em;
    height          : 7em;
    margin-right    : 1em;
    background-color: rgba($color: #fff, $alpha: .2);
    border-radius   : 50%;
    cursor          : pointer;

    transition: background .7s ease-in-out;

    &:hover {
        background-color: $teal;
    }
}

.plant {
    display        : flex;
    justify-content: flex-end;
}